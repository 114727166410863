<template>
  <el-dialog title="退款" :visible.sync="showDialog" :before-close="handleClose" width="650px">
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="处理方式：">退款</el-form-item>
      <el-form-item label="退款金额：">{{ refund_amount }}</el-form-item>
      <el-form-item label="处理结果：">
        <el-radio-group v-model="handle_state">
          <el-radio :label="51">同意退款</el-radio>
          <el-radio :label="52" v-if="state != 20">驳回申请</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="handle_state == 52" label="驳回原因：" prop="handle_message">
        <el-input v-model="form.handle_message" type="textarea" :rows="5" placeholder="请添加驳回原因"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="comfirmHndelRefund">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ['refund_amount', 'id', 'state', 'type'],
  data() {
    return {
      showDialog: !0,
      handle_state: 51,
      form: {
        handle_message: '',
      },
      rules: {
        handle_message: { required: true, message: '请填写驳货原因', trigger: 'blur' },
      },
    };
  },
  methods: {
    handleClose() {
      this.$parent.$parent.showHandleRefund = !1;
    },
    comfirmHndelRefund() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {
            handle_state: this.handle_state,
            id: this.id,
          };
          if (data.handle_state == 52) data.handle_message = this.form.handle_message;
          if (this.state == 20) {
            this.$axios.post(this.$api.samecity.service.orderRefundWallet, { id: data.id }).then(res => {
              if (res.code == 0) {
                this.handleClose();
                this.type ? this.$parent.$parent.getList() : this.$parent.$parent.getInfo();
                this.$message.success('处理成功');
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.$axios.post(this.$api.samecity.service.orderRefund, data).then(res => {
              if (res.code == 0) {
                if (data.handle_state == 51) {
                  this.$axios.post(this.$api.samecity.service.orderRefundWallet, { id: data.id }).then(res => {
                    if (res.code == 0) {
                      this.handleClose();
                      this.type ? this.$parent.$parent.getList() : this.$parent.$parent.getInfo();
                      this.$message.success('处理成功');
                    } else {
                      this.$message.error(res.msg);
                    }
                  });
                } else {
                  this.handleClose();
                  this.type ? this.$parent.$parent.getList() : this.$parent.$parent.getInfo();
                  this.$message.success('处理成功');
                }
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>