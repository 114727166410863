<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="订单编号：">
        <el-input size="small" v-model="keywords_type.order_no" @input="getKeywords($event, 1)"></el-input>
      </el-form-item>
      <el-form-item label="退款单号：">
        <el-input size="small" v-model="keywords_type.refund_sn" @input="getKeywords($event, 2)"></el-input>
      </el-form-item>
      <el-form-item label="服务名称：">
        <el-input size="small" v-model="keywords_type.goods_name" @input="getKeywords($event, 3)"></el-input>
      </el-form-item>
      <el-form-item label="收货人姓名：">
        <el-input size="small" v-model="keywords_type.receiver_name" @input="getKeywords($event, 4)"></el-input>
      </el-form-item>
      <el-form-item label="收货人手机号：">
        <el-input size="small" v-model="keywords_type.receiver_phone" @input="getKeywords($event, 5)"></el-input>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker
          :picker-options="pickerOptions"
          v-model="searchForm.create_time"
          @change="val => (val ? '' : (searchForm.create_time = []))"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="支付方式：">
        <el-select v-model="searchForm.payment_code" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="微信支付" :value="10"></el-option>
          <el-option label="支付宝支付" :value="20"></el-option>
          <el-option label="华为支付" :value="30"></el-option>
          <el-option label="余额支付" :value="40"></el-option>
          <el-option label="会员卡支付" :value="50"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-tabs type="card" v-model="searchForm.refund_state" @tab-click="tabClick">
      <el-tab-pane name="">
        <span slot="label">全部订单（{{ order_num.state_all }}）</span>
      </el-tab-pane>
      <el-tab-pane name="1">
        <span slot="label">待处理（{{ order_num.state_handle }}）</span>
      </el-tab-pane>
      <el-tab-pane name="2">
        <span slot="label">平台介入（{{ order_num.state_send }}）</span>
      </el-tab-pane>
      <el-tab-pane name="3">
        <span slot="label">师傅同意退款（{{ order_num.state_receiving }}）</span>
      </el-tab-pane>
      <el-tab-pane name="4">
        <span slot="label">平台同意退款（{{ order_num.state_receiving_refund }}）</span>
      </el-tab-pane>
      <el-tab-pane name="5">
        <span slot="label">退款成功（{{ order_num.state_success }}）</span>
      </el-tab-pane>
      <el-tab-pane name="6">
        <span slot="label">退款关闭（{{ order_num.state_close }}）</span>
      </el-tab-pane>
      <el-tab-pane name="8">
        <span slot="label">师傅驳回（{{ order_num.state_refuse }}）</span>
      </el-tab-pane>
      <el-tab-pane name="9">
        <span slot="label">代补款（{{ order_num.state_refund_money }}）</span>
      </el-tab-pane>
    </el-tabs>
    <div class="order-list">
      <table class="order-table">
        <thead>
          <tr>
            <th>服务信息</th>
            <th>服务类别</th>
            <th>支付金额</th>
            <th>退款金额</th>
            <th>支付方式</th>
            <th>付款人</th>
            <th>申请时间</th>
            <th>退款状态</th>
            <th>操作</th>
          </tr>
          <tr style="height: 10px"></tr>
        </thead>
        <tbody v-if="!list.length">
          <tr style="background: #fff">
            <td colspan="11">
              <No />
            </td>
          </tr>
        </tbody>
        <tbody v-for="(item, index) in list" :key="index">
          <tr>
            <td colspan="11">
              <div class="order-head">
                <div class="left">
                  <span class="sn">订单编号：{{ item.order_info.pay_sn }}</span>
                  <span class="sn">退款单号：{{ item.refund_sn }}</span>
                  <div v-if="item.order_info.store_id">
                    <el-tag type="success">工厂店服务</el-tag>
                    （{{ item.order_info.shop_info.store_name }}）
                  </div>
                  <el-tag v-else type="primary">平台服务</el-tag>
                </div>
                <div class="right">
                  <el-button type="text" @click="$router.push({ path: '/service/afterSaleInfo', query: { id: item.id } })">订单详情</el-button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="service-info">
                <div class="item">
                  <el-image :src="item.order_info.goods_type == 1 ? item.order_info.extend_order_extend.scene_picture[0] : item.order_info.extend_order_goods[0].service_picture"></el-image>
                  <div class="service-title">
                    <p>{{ item.order_info.goods_type == 1 ? item.order_info.message : item.order_info.extend_order_goods[0].service_title }}</p>
                  </div>
                </div>
              </div>
            </td>
            <td>{{ item.order_info.goods_type == 1 ? '快速需求' : item.order_info.goods_type == 2 ? '找安装' : item.order_info.goods_type == 3 ? '做广告' : '做设计' }}</td>
            <td>
              {{ item.order_info.amount }}
            </td>
            <td>
              {{ item.refund_amount }}
            </td>
            <td>
              {{
                item.order_info.payment_code == 10
                  ? '微信支付'
                  : item.order_info.payment_code == 20
                  ? '支付宝支付'
                  : item.order_info.payment_code == 30
                  ? '华为支付'
                  : item.payment_code == 40
                  ? '余额支付'
                  : '会员卡支付'
              }}
            </td>
            <td>
              <p v-show="item.order_info.goods_type != 1" style="line-height: 20px">
                {{ item.order_info.consignee }}
              </p>
              <p style="line-height: 20px">{{ item.order_info.consignee_mobile }}</p>
            </td>
            <td>
              {{ getDateformat(item.create_time) }}
            </td>
            <td>
              <span style="color: #409eff">
                {{
                  item.handle_state == 0
                    ? '待处理'
                    : item.handle_state == 53
                    ? '平台介入'
                    : item.handle_state == 51
                    ? '平台同意退款'
                    : item.handle_state == 52
                    ? '退款关闭'
                    : item.handle_state == 10
                    ? '师傅驳回'
                    : item.handle_state == 20
                    ? '师傅同意退款'
                    : item.handle_state == 30
                    ? '退款成功'
                    : item.handle_state == 50
                    ? '退款关闭'
                    : '代补款'
                }}
              </span>
            </td>
            <td>
              <el-button v-if="item.handle_state == 53" @click="handleRefund(item)" type="primary" size="small">平台介入</el-button>
              <el-button v-if="item.handle_state == 20" @click="handleRefund(item)" type="primary" size="small">退款</el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <handle-refund v-if="showHandleRefund" :type="1" :refund_amount="currentInfo.refund_amount" :state="currentInfo.handle_state" :id="currentInfo.id"></handle-refund>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import handleRefund from './components/handleRefund';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
    handleRefund,
  },
  data() {
    return {
      keywords_type: {
        order_no: '',
        receiver_phone: '',
        receiver_name: '',
        goods_name: '',
        refund_sn: '',
        store_id: '',
      },
      searchForm: {
        page: 1,
        rows: 10,
        keywords_type: '',
        keywords: '',
        create_time: [],
        payment_code: '',
        refund_state: 0,
      },
      currentInfo:{},
      showHandleRefund:!1,
      list: [],
      total_number: 0,
      order_num: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleRefund(row){
      this.currentInfo = row;
      this.showHandleRefund = !0;
    },
    tabClick() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList(1);
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        keywords_type: '',
        keywords: '',
        create_time: [],
        payment_code: '',
        refund_state: '',
      };
      this.resetKeywordType();
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    resetKeywordType(key) {
      for (let i in this.keywords_type) {
        if (i != key) this.keywords_type[i] = '';
      }
    },
    getKeywords(val, type) {
      let searchForm = this.searchForm;
      switch (type) {
        case 1:
          this.resetKeywordType('order_no');
          searchForm.keywords_type = 'order_no';
          break;
        case 2:
          this.resetKeywordType('refund_sn');
          searchForm.keywords_type = 'refund_sn';
          break;
        case 3:
          this.resetKeywordType('goods_name');
          searchForm.keywords_type = 'goods_name';
          break;
        case 4:
          this.resetKeywordType('receiver_name');
          searchForm.keywords_type = 'receiver_name';
          break;
        case 5:
          this.resetKeywordType('receiver_phone');
          searchForm.keywords_type = 'receiver_phone';
          break;
      }
      searchForm.keywords = val;
    },
    getList(is_tab) {
      let searchForm = Object.assign(this.searchForm);
      let data = {
        page: searchForm.page,
        rows: searchForm.rows,
      };
      if (searchForm.refund_state) {
        data.refund_state = searchForm.refund_state;
      }
      if (searchForm.keywords) {
        data.keywords = searchForm.keywords;
        data.keywords_type = searchForm.keywords_type;
      }
      if (searchForm.payment_code) {
        data.payment_code = searchForm.payment_code;
      }
      if (searchForm.create_time.length) {
        for (let i in searchForm.create_time) {
          searchForm.create_time[i] = searchForm.create_time[i] / 1000;
        }
        data.create_time = searchForm.create_time;
      }
      this.$axios.post(this.$api.samecity.service.orderRefundList, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
      if (!is_tab) {
        this.$axios.post(this.$api.samecity.service.orderRefundNum, data).then(res => {
          if (res.code == 0) {
            this.order_num = res.result;
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .order-table {
    width: 100%;
    font-size: 14px;
    tbody {
      border: 1px solid #ebeef5;
    }
    thead tr th {
      padding: 15px;
      text-align: center;
    }
    thead tr {
      background: #f8f9fa;
      th:first-child {
        width: 280px;
      }
      th:last-child {
        width: 150px;
      }
    }
    tbody tr:first-child {
      border-bottom: 1px solid #ebeef5;

      background: #f8f9fa;
      td {
        padding: 0 20px;
      }
    }
    tbody tr:last-child {
      td {
        text-align: center;
      }
      td:first-child {
        width: 280px;
      }
      td:last-child {
        width: 150px;
      }
    }
    .service-info {
      width: 300px;
      flex: none;
      padding-left: 20px;

      .item {
        height: 70px;
        display: flex;
        align-items: center;
        .el-image {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          border-radius: 5px;
        }
        .service-title {
          text-align: left;
          flex: 1;
          p:nth-child(1) {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
    .order-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        .sn {
          margin-right: 70px;
        }
        div {
          display: flex;
          align-items: center;
        }
        .el-button {
          cursor: auto;
        }
      }
    }
  }
}
</style>